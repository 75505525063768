<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import Banners from "@/components/widgets/banners";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
      'Team': 'Time',
      'Dashboard': 'Painel',
      'Package': 'Pacote',
      'Graduation': 'Graduação',
      'Points': 'Pontos',
      'Referral link': 'Link de indicação',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Pontos',
    },
    es: {
      'Team': 'Equipo',
      'Dashboard': 'Panel',
      'Package': 'Paquete',
      'Graduation': 'Graduación',
      'Points': 'Puntos',
      'Referral link': 'Link de patrocineo',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Puntos',
    }
  },
  components: {
    Layout,
    Banners,
    Stat,
  },
  computed: {
    ...mapState({
      account: state => state.account
    }),
  },
  data() {
    return {
      total: {
        bonus: "R$ ***,**",
        balance: "R$ ***,**",
      },

      statData: null,

      referral: process.env.VUE_APP_URL_PAINEL + this.$store.state.account.user.username
    };
  },
  methods: {
    getTotalBonus() {
      api
        .get('report/bonus/total')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.bonus = this.$options.filters.currency(response.data.total)
            this.getStatData();
          }
        })
    },
    getTotalBalance() {
      api
        .get('wallet/balance/network')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.balance = this.$options.filters.currency(response.data.balance)
            this.getStatData();
          }
        })
    },
    getStatData() {
      this.statData = [
        {
          title: "Total de Bônus",
          value: this.total.bonus
        },
        {
          title: "Saldo Disponível",
          value: this.total.balance
        }
      ]
    },
  },
  mounted() {
    this.getTotalBonus()
    this.getTotalBalance()
    this.getStatData()
  }
};
</script>

<template>
  <Layout>
    <Banners></Banners>

    <div class="card">
      <div class="card-body py-2 d-flex align-items-center">
        <div class="mt-1">
          <i class="bx bx-link font-size-20 mr-1"></i>
        </div>
        <h5 class="m-0 align-items-center">
          Link de indicação
        </h5>
        <div class="mt-1 ml-3">
          <ShareNetwork network="whatsapp" url="" :title="referral">
            <i class="bx bxl-whatsapp font-size-24 text-dark p-0"></i>
          </ShareNetwork>
        </div>
        <div class="mt-1 ml-2">
          <button class="btn btn-link p-0 m-0" v-clipboard:copy="referral
            " v-on:click="$toast.success('Copiado!');">
            <i class="bx bx-copy font-size-20 text-dark"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-4 col-lg-4 col-xl-4">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>
  </Layout>
</template>